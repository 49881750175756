import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function statsFilter({
  startDate,
  endDate,
  receiverKind,
  receiverName,
  instanceStep,
  energyKind,
  instanceCategory,
  instanceSubject,
  senderAccept,
  pendingInstances,
  responseTime,
  improperResponseTime
}) {
  const route = "/api/parameter_stats/statsfilter/";
  let data = { 
    startDate: startDate,
    endDate: endDate,
    receiverKind: receiverKind,
    receiverName: receiverName,
    // 'instanceStatus': this.state.instanceStatus,
    instanceStep: instanceStep,
    energyKind: energyKind,
    instanceCategory: instanceCategory,
    instanceSubject: instanceSubject,
    senderAccept: senderAccept,
    pendingInstances: pendingInstances,
    responseTime: responseTime,
    // 'declinedBy': this.state.declinedBy,
    improperResponseTime: improperResponseTime

  };
  let asyncResult = await postAsync(route, data);
  return asyncResult;
}