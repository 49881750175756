import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import Login from "views/examples/Login.js";

function setToken(userToken) {
    // verifyToken(userToken);
    localStorage.setItem('token', userToken);
}

async function verifyToken(token) {
    //here
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    })
        // .then(res => res.json())
        .then(
            (result) => {
                if (result.status !== 200) {
                    localStorage.removeItem('token');
                    window.location.replace("/auth/login");
                }
                else {
                    localStorage.setItem('token', token);
                }
            },
            (error) => {
                localStorage.removeItem('token');
                window.location.replace("/auth/login");
            }
        )
};

function App() {

    const token = localStorage.getItem('token');

    if (!token) {
        return <Login setToken={setToken} />
    }
    else {
        verifyToken(token);

        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/admin" render={props => <AdminLayout {...props} />} />
                    <Route path="/auth" render={props => <Login {...props} />} />
                    <Redirect from="/" to="/admin/index" />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
