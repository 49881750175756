import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import EnomosLogo from '../../assets/img/brand/enomos-logo.jpg'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../assets/css/datatable.css';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class InstanceExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serialNo: null,
            error: null,
            data: [],
            serialnumbers: [],
            userDetails: [],
            userEmail: '',
            userPhone: '',
            dataDict: [],
            replies: [],
            dummy: [],
            chat: []
        };

    }

    componentDidMount() {

        const handle = this.props.match.params.id;
        document.getElementById('sidenav-main').style.display = 'none';
        document.getElementById('navbar-main').style.display = 'none';
        document.querySelector('.main-content').style.margin = '0px';
        document.querySelector('.copyright').style.display = 'none';

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/export/historyexportunique/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    const filledRows = result[0].filter(element => element['Data'] != '')
                    const filledMessages = result[1].filter(element => element['Data'] != '')
                    this.setState({
                        serialNo: result[0][0].Data,
                        dataDict: result[0],
                        replies: result[1],
                        dummy: {
                            columns: [
                                {
                                    label: '',
                                    field: 'actionId',
                                    sort: 'asc',
                                    width: 200
                                },
                                {
                                    label: '',
                                    field: 'Data',
                                    sort: 'asc',
                                    width: 100
                                }
                            ],
                            rows: filledRows
                        },
                        chat: {
                            columns: [
                                {
                                    label: '',
                                    field: 'actionId',
                                    sort: 'asc',
                                    width: 200
                                },
                                {
                                    label: '',
                                    field: 'Data',
                                    sort: 'asc',
                                    width: 100
                                }
                            ],
                            rows: filledMessages
                        }
                    })


                })

            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    this.setState({
                        userDetails: result,
                        userEmail: result.Email,
                        userPhone: ''
                    });
                })
        }


    }



    print() {
        // printButton
        document.getElementById('printSection').style.display = 'none';
        window.print();
    }

    ExportToExcel(event, id) {
        event.preventDefault();

        if (localStorage.getItem('token')) {
            axios.get(process.env.REACT_APP_API_LINK + "/api/export/historyexportuniquecsv/" + id + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
                responseType: 'blob'
            })
                .then(result => {
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Αίτηση ' + this.state.serialNo + '.xlsx');

                    document.body.appendChild(link);

                    link.click();

                    link.parentNode.removeChild(link);
                })
        }

    }

    render() {
        window.onafterprint = function () {
            document.getElementById('printSection').style.display = 'block';
        }
        return (
            <>

                <div class="container-fluid" style={{ margin: '0' }}>
                    <img
                        width="200"
                        alt='enomos'
                        src={EnomosLogo}
                        id="rae-logo"
                    /><br /> <br />
                    {/* <b>Στοιχεία Καταναλωτή: </b> <br />
                    Όνομα: {this.state.userDetails.Name} <br />
                    Επώνυμο: {this.state.userDetails.LastName} <br />
                    {this.state.userEmail.includes("@facebook.com") ?
                        <></> 
                        : 
                        <>
                            Email: {this.state.userDetails.Email} <br />
                        </>

                    }
                    {this.state.userPhone == ("0000000000") ?
                        <></> 
                        : 
                        <>
                            Τηλέφωνο: {this.state.userDetails.phoneNumber} <br />
                        </>

                    } */}

                    {/* <GenericHeader /> */}
                    <div id="printSection" style={{
                        marginTop: '20px', zIndex: '1000', marginBottom: '20px',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>

                        <Button
                            color="primary"
                            size="sm"
                            title="Στοιχεία"
                            onClick={() => this.print()}
                        >
                            Εκτύπωση
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            title="Στοιχεία"
                            onClick={() => this.props.history.goBack()}
                        >
                            Επιστροφή στο ENOMOS
                        </Button>
                        <Button
                            color="success"
                            size="sm"
                            onClick={e => { this.ExportToExcel(e, this.props.match.params.id) }}
                        >
                            Εξαγωγή Αίτησης
                        </Button>
                    </div>
                    <br />
                    {/* <b style={{ fontSize: '20px' }}>Αιτήματα Καταναλωτή: </b> <br /> */}
                    <div className="border-0 col-md-12 align-items-center" id="Stoixeia">
                        <strong>ΣΤΟΙΧΕΙΑ ΑΙΤΗΣΗΣ ΚΑΤΑΝΑΛΩΤΗ ΣΤΗΝ ΠΛΑΤΦΟΡΜΑ MYRAE</strong><br /><br />

                    </div>
                    <CardHeader className="border-0 col-md-12 overflow-hidden">
                        <Row className="align-items-center" id="mdbdttableexport">
                            <div className="col">
                                <MDBDataTable
                                    responsive
                                    bordered
                                    exportToCSV
                                    hover
                                    striped
                                    data={this.state.dummy}
                                    id="datatable-1"
                                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={25}
                                    noDataComponent
                                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                />
                            </div>

                        </Row>
                    </CardHeader>


                    <div className="border-0 col-md-12 align-items-center" id="Stoixeia">
                        <br /><strong>ΙΣΤΟΡΙΚΟ ΔΙΑΛΟΓΟΥ</strong><br /><br />
                    </div>
                    <CardHeader className="border-0 col-md-12 overflow-hidden">
                        <Row className="align-items-center" id="mdbdttableexport">
                            <div className="col">
                                <MDBDataTable
                                    responsive
                                    bordered
                                    exportToCSV
                                    hover
                                    striped
                                    data={this.state.chat}
                                    id="datatable-2"
                                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={25}
                                    noDataComponent
                                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                />
                            </div>

                        </Row>
                    </CardHeader>





                </div>
            </>
        )
    };

}


export default InstanceExport;