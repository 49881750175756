import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';


class ImproperResponseTimeInstances extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            over10Instances: [],
            over30Instances: [],
            user: []
        };
    }

    async componentDidMount() {

        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.PersonRole != 'Admin' && result.PersonRole != 'Guest') {
                        this.props.history.goBack();
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/improperinstances/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var myuser = this.state.user.PersonRole;
                    const instances2 = result[0];
                    instances2.forEach(function (part, index) {
                        this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
                        var dateArray = ((this[index].StartedAt).toString()).split('T');
                        var timeArray = dateArray[1].split(':');
                        this[index].NewSubmittedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
                        this[index].Receiver = this[index].Receiver[0];
                        this[index].Buttons =
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    href={"/admin/details/" + this[index].Id}
                                    size="sm"
                                    title="Στοιχεία"
                                >
                                    Λεπτομέρειες
                                </Button>
                                {/* {
                                    myuser == 'Admin' ?
                                        <>
                                            <Button
                                                color="danger"
                                                onClick={() => { this.handleClick(this[index].Id) }}
                                                size="sm"
                                                title="Διαγραφή"
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </> : <> </>} */}
                            </React.Fragment>

                    }, instances2);
                    this.datas = {
                        columns: [
                            {
                                label: 'Α/Α ▼',
                                field: 'SerialNumber',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Ενεργειες',
                                field: 'Buttons',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Κατασταση ▼',
                                field: 'NewStatus',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Παραληπτης ▼',
                                field: 'Receiver',
                                sort: 'asc',
                                width: 150
                            }, ,
                            {
                                label: 'Ημ/νια Υποβολης ▼',
                                field: 'NewSubmittedAt',
                                sort: 'asc',
                                width: 150
                            }
                        ],
                        rows: result[0],
                    };
                    this.setState({
                        isLoaded: true,
                        over10Instances: result[[0]],
                    });
                    const instances3 = result[1];
                    instances3.forEach(function (part, index) {
                        this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
                        var dateArray = ((this[index].StartedAt).toString()).split('T');
                        var timeArray = dateArray[1].split(':');
                        this[index].NewSubmittedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
                        this[index].Receiver = this[index].Receiver[0];
                        this[index].Buttons =
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    href={"/admin/details/" + this[index].Id}
                                    size="sm"
                                    title="Στοιχεία"
                                >
                                    Λεπτομέρειες
                                </Button>
                                {/* {
                                    myuser == 'Admin' ?
                                        <>
                                            <Button
                                                color="danger"
                                                onClick={() => { this.handleClick(this[index].Id) }}
                                                size="sm"
                                                title="Διαγραφή"
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </> : <> </>} */}
                            </React.Fragment>

                    }, instances3);
                    this.datas2 = {
                        columns: [
                            {
                                label: 'Α/Α ▼',
                                field: 'SerialNumber',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Ενεργειες',
                                field: 'Buttons',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Κατασταση ▼',
                                field: 'NewStatus',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Παραληπτης ▼',
                                field: 'Receiver',
                                sort: 'asc',
                                width: 150
                            }, ,
                            {
                                label: 'Ημ/νια Υποβολης ▼',
                                field: 'NewSubmittedAt',
                                sort: 'asc',
                                width: 150
                            }
                        ],
                        rows: result[1],
                    };
                    this.setState({
                        isLoaded: true,
                        over30Instances: result[[1]],
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );


    }

    handleClick = instanceId => {
        if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε την συγκεκριμένη αναφορά;") == true) {
            if (localStorage.getItem('token')) {
                axios.delete(process.env.REACT_APP_API_LINK + '/api/workflowinstances/' + instanceId + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                    .then(res => {
                        window.location.reload(false);
                    })
            }
        }
        else { }
    }

    ExportToExcel = () => {
        if (localStorage.getItem('token')) {

            fetch(process.env.REACT_APP_API_LINK + '/api/export/parameterexportexcel/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'responseType': 'blob'
                }),
                body: JSON.stringify({
                    'startDate': "-",
                    'endDate': "-",
                    'selectedSupplierMaintainer': "-",
                    'selectedComplaint': "ΟΛΑ",
                    'receiverKind': "ΟΛΑ",
                    'receiverName': "ΟΛΑ",
                    'energyKind': "ΟΛΑ",
                    'activeComplaints': "0"
                })
            })
                .then(response => response.blob())
                .then(function (myBlob) {
                    var objectURL = URL.createObjectURL(myBlob);
                    const link = document.createElement('a');
                    link.href = objectURL;
                    link.setAttribute('download', `Αιτήματα Παραπόνων.xlsx`);

                    document.body.appendChild(link);

                    link.click();

                    link.parentNode.removeChild(link);
                });

        }
    }

    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--8" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Αναφορές με Μη προσήκουσα χρονικά απόκριση του Προμηθευτή/Διαχειριστή</h3>
                                        </Col>
                                        {/* <Col className="text-right" xs="4">
                      {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' ?
                        <>
                          <Button
                            color="success"
                            size="sm"
                            onClick={this.ExportToExcel}
                          >
                            Εξαγωγή Αιτήσεων
                          </Button>
                        </> :
                        <>
                          <Button
                            color="success"
                            size="sm"
                            onClick={this.ExportToExcel}
                          >
                            Εξαγωγή Αιτήσεων
                          </Button>
                        </>}
                    </Col> */}
                                    </Row>

                                </CardHeader>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <MDBDataTable
                                                bordered
                                                exportToCSV
                                                hover
                                                responsive
                                                striped
                                                data={this.datas}
                                                id="mdbdttable"
                                                entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                noDataComponent="Δεν υπάρχουν δεδομένα"
                                                searchLabel="Αναζήτηση"
                                                paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                            />
                                        </div>

                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                <br />
                <br />
                <Container className="mt--8" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Αναφορές με Μη προσήκουσα χρονικά απόκριση του Καταναλωτή</h3>
                                        </Col>
                                        {/* <Col className="text-right" xs="4">
                      {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' ?
                        <>
                          <Button
                            color="success"
                            size="sm"
                            onClick={this.ExportToExcel}
                          >
                            Εξαγωγή Αιτήσεων
                          </Button>
                        </> :
                        <>
                          <Button
                            color="success"
                            size="sm"
                            onClick={this.ExportToExcel}
                          >
                            Εξαγωγή Αιτήσεων
                          </Button>
                        </>}
                    </Col> */}
                                    </Row>

                                </CardHeader>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <MDBDataTable
                                                bordered
                                                exportToCSV
                                                hover
                                                responsive
                                                striped
                                                data={this.datas2}
                                                id="mdbdttable2"
                                                entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                noDataComponent="Δεν υπάρχουν δεδομένα"
                                                searchLabel="Αναζήτηση"
                                                paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                            />
                                        </div>

                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ImproperResponseTimeInstances;
