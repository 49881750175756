import React from "react"
// node.js library that concatenates classes (strings)
import classnames from "classnames"
import EnomosPicture from "../assets/img/brand/enomos.png"
import axios from "axios"
import "../assets/css/datatable.css"
import Select from "react-select"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap"

import Header from "components/Headers/GenericHeader.js"
import Moment from "moment"
import "moment/locale/el"
import { MDBDataTable } from "mdbreact"
import { CommonLoading } from "react-loadingg"
class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      user_token: "",
      activeNav: 1,
      isLoaded: false,
      isLoading: false,
      loadingInstances: true,
      instances: [],
      user: [],
      datas: [],
      paroxoi: [],
      selected_paroxos: -1,
      selected_category: -1,
      tabledata: [],
      dummy: [],
      usersdict: {},
      count_instances: {},
    }
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            })
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            })
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/enomos/all/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result)
            const self = this
            const instances = [...result]
            instances.forEach(function (instance, index) {
              if (instance.Status === 1) {
                this[index].NewStatus = "Επεξεργασία Αιτήματος"
              } else if (instance.Status === 2) {
                this[index].NewStatus =
                  instance.CompletionStatus === 0
                    ? "Επιτυχής Διαμεσολάβηση"
                    : instance.CompletionStatus === 1
                    ? "Ανεπιτυχής Διαμεσολάβηση"
                    : "Unknown Status" 
              } else if (instance.Status === 3) {
                this[index].NewStatus = "Απευθείας Απόρριψη"
              } else {
                this[index].NewStatus = "Unknown Status" 
              }

              const dateArray = this[index].StartedAt.toString().split("T")
              const timeArray = dateArray[1].split(":")
              this[index].StartedAt =
                dateArray[0] + " - " + timeArray[0] + ":" + timeArray[1]
              this[index].FullName =
                this[index].ConsumerLastName + " " + this[index].ConsumerName
              this[index].CompletionReason = this[index].Buttons = (
                <React.Fragment>
                  {self.state.user.PersonRole == "Admin" ||
                  self.state.user.PersonRole == "Lawyer" ? (
                    <>
                      <Button
                        color='primary'
                        href={"/admin/details/" + this[index].Id}
                        size='sm'
                      >
                        Λεπτομέρειες
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        color='primary'
                        href={"/admin/instance/" + this[index].Id}
                        size='sm'
                      >
                        Λεπτομέρειες
                      </Button>
                    </>
                  )}
                </React.Fragment>
              )

              this[index].Id = null
            }, instances)
            this.setState({
              loadingInstances: false,
              dummy: {
                columns: [
                  {
                    label: "Α/Α ▼",
                    field: "SerialNumber",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Α/Α myRAE ▼",
                    field: "myRAEnumber",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Διαμεσολαβητησ ▼",
                    field: "Mediator",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Καταναλωτης ▼",
                    field: "FullName",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Κατασταση ▼",
                    field: "NewStatus",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Ημ/νια Υποβολης ▼",
                    field: "StartedAt",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Ενεργειες",
                    field: "Buttons",
                    sort: "asc",
                    width: 150,
                  },
                ],
                rows: instances,
              },
            })
          },
          (error) => {
            this.setState({
              isLoaded: true,
              loadingInstances: false,
              error,
            })
          }
        )
    }

    // this.DemoCalls();
  }

  ExportToExcel = () => {
    if (localStorage.getItem("token")) {
      this.setState({ isLoading: true })

      fetch(
        process.env.REACT_APP_API_LINK + "/api/export/parameterexportexcel/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
            // 'responseType': 'blob'
          }),
          body: JSON.stringify({
            startDate: "-",
            endDate: "-",
            selectedSupplierMaintainer: "-",
            selectedComplaint: "ΟΛΑ",
            receiverKind: "ΟΛΑ",
            receiverName: "ΟΛΑ",
            energyKind: "ΟΛΑ",
          }),
        }
      )
        .then((response) => response.blob())
        .then(function (myBlob) {
          var objectURL = URL.createObjectURL(myBlob)
          const link = document.createElement("a")
          link.href = objectURL
          link.setAttribute("download", `Αιτήματα Παραπόνων.xlsx`)

          document.body.appendChild(link)

          link.click()

          link.parentNode.removeChild(link)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--8' fluid>
          <Row>
            <Col xl={{ size: 4, offset: 0 }}>
              {" "}
              {/* Adjust 'size' for width and 'offset' for alignment */}
              <Card className='card-stats mb-4 mb-xl-0'>
                <CardBody>
                  <Row>
                    {/* Image Column */}
                    <Col xl='12'>
                      <img
                        src={EnomosPicture}
                        alt='Description'
                        style={{ maxWidth: "100%", height: "80px" }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.loadingInstances ? (
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <CommonLoading />
            </>
          ) : (
            <>
              <Row className='mt-5'>
                <Col className='mb-5 mb-xl-0' xl='12'>
                  <Card className='shadow'>
                    <CardHeader className='border-0'>
                      <Row className='align-items-center'>
                        <Col xs='8'>
                          {/* <h3 className="mb-0">Αιτήματα Παραπόνων</h3> */}
                        </Col>
                        <Col className='text-right' xs='4'>
                          {/* <Button
                            color="success"
                            size="sm"
                            onClick={this.ExportToExcel}
                          >
                            {this.state.isLoading ? 'Περιμένετε...' : 'Εξαγωγή Αιτήσεων'}
                          </Button> */}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardHeader className='border-0'>
                      <Row className='align-items-center'>
                        <div className='col'>
                          <MDBDataTable
                            responsive
                            bordered
                            exportToCSV
                            hover
                            strip
                            data={this.state.dummy}
                            id='mdbdttable'
                            entriesOptions={[10, 25, 50, 100, 500, 1000]}
                            entries={10}
                            noDataComponent='Δεν υπάρχουν δεδομένα'
                            searchLabel='Αναζήτηση'
                            paginationLabel={["Προηγούμενο", "Επόμενο"]}
                            infoLabel={[
                              "Εμφανίζονται",
                              "έως",
                              "από",
                              "καταχωρήσεις",
                            ]}
                            entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                          />
                        </div>
                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <div class='push'></div>
        </Container>
      </>
    )
  }
}

export default Index
