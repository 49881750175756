import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import '../../assets/css/datatable.css';
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";
import {MDBDataTable} from 'mdbreact'

const Users = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);

  const toggleUser = (user_id) => {
    if (window.confirm("Είστε σίγουροι ότι θέλετε να πραγματοποιήσετε την συγκεκριμένη ενέργεια;")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/toggleEnabledValue/", {
        method: 'post',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: JSON.stringify({
          UserId: user_id
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          window.location.reload();
        }
      )
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseUser = await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
          method: 'get',
          headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        const resultUser = await responseUser.json();

        if (resultUser.PersonRole !== 'Admin' && resultUser.PersonRole !== 'Head User') {
          history.goBack()
        }

        setUser(resultUser);

        const responseUsers = await fetch(process.env.REACT_APP_API_LINK + "/api/user/enomos/all/" + resultUser.Photo + "/", {
          method: 'get',
          headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        const resultUsers = await responseUsers.json();

        setUsers(resultUsers.map(part => ({
          ...part,
          NewPersonRole: part.PersonRole === 'Admin' ? 'Admin Mediator' : part.PersonRole === 'Lawyer' ? 'Mediator' : part.PersonRole,
          NewPhoto: part.Photo === 'ΡΑΕ' ? 'ΔΙΑΜΕΣΟΛΑΒΗΤΗΣ' : part.Photo,
          Buttons: (
            <React.Fragment>
              <Button
                color="primary"
                href={"/admin/user/" + part.Id}
                size="sm"
              >
                Επεξεργασία
              </Button>
              {part.Enabled ? (
                <Button
                  color="warning"
                  onClick={() => toggleUser(part.Id)}
                  size="sm"
                  title="Απενεργοποίηση"
                >
                  Απενεργοποίηση
                </Button>
              ) : (
                <Button
                  color="success"
                  onClick={() => toggleUser(part.Id)}
                  size="sm"
                  title="Ενεργοποίηση"
                >
                  Ενεργοποίηση
                </Button>
              )}
            </React.Fragment>
          ),
        })));

        setIsLoaded(true);
      } catch (error) {
        setIsLoaded(true);
        setError(error);
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <GenericHeader />
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Χρήστες</h3>
                    </div>
                    <div className="col text-right">
                      {(user.PersonRole === 'Head User') ? 
                        <>
                          <Button color="success" href={"/admin/newuser"} size="sm">
                            Προσθήκη Χρήστη Εταιρείας
                          </Button>
                        </> : <></>
                      }
                      {(user.PersonRole === 'Admin') ?
                      <>
                        <Button color="success" href={"/admin/newuser"} size="sm">
                            Προσθήκη Χρήστη Εταιρείας
                          </Button>
                          <Button color="info" href={"/admin/newlawyer"} size="sm">
                            Προσθήκη Διαμεσολαβητή
                          </Button>
                          <Button color="warning" href={"/admin/newadmin"} size="sm">
                            Προσθήκη νέου Admin
                          </Button>
                      </> : <></>
                      }
                    </div>
                  </Row>
                </CardHeader>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        strip
                        data={{
                          columns: [
                            { label: 'ονοματεπωνυμο ▼', field: 'DisplayName', sort: 'asc', width: 100 },
                            { label: 'Email ▼', field: 'Email', sort: 'asc', width: 150 },
                            { label: 'Οργανισμος ▼', field: 'NewPhoto', sort: 'asc', width: 150 },
                            { label: 'Κατασταση ▼', field: 'NewPersonRole', sort: 'asc', width: 100 },
                            { label: 'Ενεργειες', field: 'Buttons', sort: 'asc', width: 150 },
                          ],
                          rows: users,
                        }}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]}
                        entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default Users;
