import Index from "views/Index.js";
import Login from "views/examples/Login.js";
import Users from "views/examples/Users.js";
import User from "views/examples/User.js";
import NewUser from "views/examples/newUser.js";
import NewLawyer from "views/examples/newLawyer.js";
import NewAdmin from "views/examples/newAdmin.js";
import InstanceMenu from "views/examples/InstanceMenu.js";
import Instances from "views/examples/Instances.js";
import OldInstances from "views/examples/OldInstances.js";
import newInstances from "views/examples/newInstances.js";
import declinedInstances from "views/examples/declinedInstances.js";
import urgentInstances from "views/examples/urgentInstances";
import adminPassedInstances from "views/examples/adminPassedInstances";
import consumerPassedInstances from "views/examples/consumerPassedInstances";
import consumerIntimeInstances from "views/examples/consumerIntimeInstances";
import ImproperResponseTimeInstances from "views/examples/ImproperResponseTimeInstances";
import InstanceDetails from "views/examples/InstanceDetails.js";
import Instance from "views/examples/Instance.js";
import newInstance from "views/examples/newInstance.js";
import createInstance from "views/examples/createInstance.js";
import ConsumerSupport from "views/examples/consumerSupport.js";
import Holidays from "views/examples/Holidays.js";
import Holiday from "views/examples/Holiday.js";
import Workflows from "views/examples/Workflows.js";
import Workflow from "views/examples/Workflow.js";
import WorkflowSteps from "views/examples/WorkflowSteps.js";
import StepActions from "views/examples/StepActions.js";
import Documents from "views/examples/Documents.js";
import Statistics from "views/examples/Statistics.js";
import ParameterStatistics from "views/examples/parameterStatistics.js";
import Profile from "views/examples/Profile.js";
import Settings from "views/examples/Settings.js";
import Consumers from "views/examples/Consumers.js";
import Consumer from "views/examples/Consumer.js";
import InstanceExport from "views/examples/InstanceExport.js";
import exportFilter from "views/examples/exportFilter.js";

var routes = [
  {
    path: "/documents",
    name: "Arxeia",
    icon: "ni ni-tv-2 text-primary",
    component: Documents,
    layout: "/admin"
  },
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Χρήστες",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/user/:id",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: User,
    layout: "/admin"
  },
  {
    path: "/newuser",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: NewUser,
    layout: "/admin"
  },
  {
    path: "/newlawyer",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: NewLawyer,
    layout: "/admin"
  },
  {
    path: "/newadmin",
    name: "Διαχειριστής",
    icon: "ni ni-single-02 text-yellow",
    component: NewAdmin,
    layout: "/admin"
  },
  {
    path: "/instancemenu",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceMenu,
    layout: "/admin"
  },
  {
    path: "/instances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: Instances,
    layout: "/admin"
  },
  {
    path: "/oldinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: OldInstances,
    layout: "/admin"
  },
  {
    path: "/declinedinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: declinedInstances,
    layout: "/admin"
  },
  {
    path: "/newinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: newInstances,
    layout: "/admin"
  },
  {
    path: "/urgentinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: urgentInstances,
    layout: "/admin"
  },
  {
    path: "/adminpassedinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: adminPassedInstances,
    layout: "/admin"
  },
  {
    path: "/consumerpassedinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: consumerPassedInstances,
    layout: "/admin"
  },
  {
    path: "/consumerintimeinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: consumerIntimeInstances,
    layout: "/admin"
  },
  {
    path: "/improperresponsetimeinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: ImproperResponseTimeInstances,
    layout: "/admin"
  },
  {
    path: "/instance/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: Instance,
    layout: "/admin"
  },
  {
    path: "/instancexport/:id",
    name: "Εξαγωγή Αιτήματος",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceExport,
    layout: "/admin"
  },
  {
    path: "/exportfilter/",
    name: "Παραμετρική Εξαγωγή",
    icon: "ni ni-circle-08 text-pink",
    component: exportFilter,
    layout: "/admin"
  },
  {
    path: "/details/:id",
    name: "Στοιχεία",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceDetails,
    layout: "/admin"
  },
  {
    path: "/newinstance",
    name: "Nέα Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: newInstance,
    layout: "/admin"
  },
  {
    path: "/createinstance",
    name: "Δημιουργία Ροής",
    icon: "ni ni-circle-08 text-pink",
    component: createInstance,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Υποστήριξη",
    icon: "ni ni-circle-08 text-pink",
    component: Settings,
    layout: "/admin"
  },
  {
    path: "/support",
    name: "Υποστήριξη",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerSupport,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Προφίλ χρήστη",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/statistics",
    name: "Στατιστικά",
    icon: "ni ni-circle-08 text-pink",
    component: Statistics,
    layout: "/admin"
  },
  {
    path: "/parameterstatistics",
    name: "Παραμετρικά Στατιστικά",
    icon: "ni ni-circle-08 text-pink",
    component: ParameterStatistics,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/consumers",
    name: "Consumers",
    icon: "ni ni-key-25 text-info",
    component: Consumers,
    layout: "/admin"
  },
  {
    path: "/consumer/:id",
    name: "Consumer",
    icon: "ni ni-key-25 text-info",
    component: Consumer,
    layout: "/admin"
  },
  {
    path: "/holidays",
    name: "Αργίες",
    icon: "ni ni-single-02 text-yellow",
    component: Holidays,
    layout: "/admin"
  },
  {
    path: "/holiday/:id",
    name: "Αργία",
    icon: "ni ni-single-02 text-yellow",
    component: Holiday,
    layout: "/admin"
  },
];

export default routes;
