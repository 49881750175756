/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import "@fortawesome/fontawesome-free/css/all.min.css";
import deddieLogo from "../../assets/img/brand/enomos-logo.jpg";
import enomosLogo from "../../assets/img/brand/enomos.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false
  };
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: []
    };

    this.activeRoute.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };

  // creates the links that appear in the left menu / Sidebar
  // createLinks = routes => {
  //   return routes.map((prop, key) => {
  //     return (
  //       <NavItem key={key}>
  //         <NavLink
  //           to={prop.layout + prop.path}
  //           tag={NavLinkRRD}
  //           onClick={this.closeCollapse}
  //           activeClassName="active"
  //         >
  //           <i className={prop.icon} />
  //           {prop.name}
  //         </NavLink>
  //       </NavItem>
  //     );
  //   });
  // };

  createLinks = routes => {
    if (this.state.isLoaded == false) {
      return;
    }

    return (
      <>
        <NavItem key={1}>
          <NavLink
            to={'/admin/index'}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
            style={{ color: '#02497F' }}
          >
            <i className='fa fa-home text-primary mr-3' />
            Αρχική
          </NavLink>
          <hr className="my-3" style={{ color: '#02497F' }} />
        </NavItem>

        {/* <NavItem key={2}>
            <NavLink
              to={'/admin/instancemenu'}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              style={{color: '#02497F'}}
            >          
              <i className="fa fa-stream text-primary mr-3" />
              Αιτήματα Παραπόνων
            </NavLink>
          </NavItem> */}


        {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Head User' ?
          <>
            <NavItem key={5}>
              <NavLink
                to={'/admin/users'}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                style={{ color: '#02497F' }}
              >
                <i className='fa fa-user-friends text-primary mr-3' />
                Χρήστες
              </NavLink>
            </NavItem>
          </> : <> </>}
        {/* {this.state.user.PersonRole == 'Admin' &&
          <>
            <NavItem key={5}>
              <NavLink
                to={'/admin/consumers'}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                style={{ color: '#02497F' }}
              >
                <i className='fa fa-users text-primary mr-3' />
                Καταναλωτές
              </NavLink>




              <hr className="my-3" style={{ color: '#02497F' }} />
            </NavItem>

            <NavItem>
              <NavLink
                to={'/admin/documents'}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                style={{ color: '#02497F' }}
              >
                <i className='fa fa-trash text-primary mr-3' />
                Κάδος Ανακύκλωσης
              </NavLink>
            </NavItem>

            <NavItem key={6}>
              <NavLink
                to={'/admin/holidays'}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                style={{ color: '#02497F' }}
              >
                <i className='fa fa-calendar text-primary mr-3' />
                Αργίες
              </NavLink>
              <hr className="my-3" style={{ color: '#02497F' }} />
            </NavItem>
          </>} */}


        {/* <NavItem key={9}>
          <NavLink
            to={'/admin/statistics'}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            style={{ color: '#02497F' }}
          >
            <i className='fa fa-chart-bar text-primary mr-3' />
            Στατιστικά
          </NavLink>
        </NavItem> */}

        {/* <NavItem key={7}>
          <NavLink
            to={'/admin/parameterstatistics'}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            style={{ color: '#02497F' }}
          >
            <i className='fa fa-chart-bar text-primary mr-3' />
            Παραμετρικά Στατιστικά
          </NavLink>
        </NavItem> */}

        {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Head User' || this.state.user.PersonRole == 'User' ?
          <>
            <NavItem key={8}>
              <NavLink
                to={'/admin/exportfilter'}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                style={{ color: '#02497F' }}
              >
                <i className='fa fa-outdent text-primary mr-3' />
                Παραμετρική Εξαγωγή
              </NavLink>
            </NavItem>
          </> : <></>
        } */}

        {/* {this.state.user.PersonRole == 'Admin' ? 
            <>
              <NavItem key={12}>
              <hr className="my-3" style={{color: '#02497F'}}/>
                <NavLink
                  to={'/admin/management'}
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  style={{color: '#02497F'}}
                >
                  <i className='fa fa-cogs text-primary mr-3'/>
                  Διαχείριση CRM
                </NavLink>
                
              </NavItem>
            </> : <> </>} */}

      </>
    );
  };

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light"
        expand="md"
        id="sidenav-main"
        style={{ backgroundColor: "white" }}
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <img
            style={{ width: '50%', margin: 'auto' }}
            alt='deddie'
            src={deddieLogo}
          />
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="ni ni-single-02">
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/consumer/support" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Υποστήριξη</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Αποσύνδεση</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3" />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
