import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Badge
} from "reactstrap";

import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      notifications: []
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/enomos/get_notifications/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              notifications: result
            });
            console.log(this.state.notifications);
          }
        )
    };
  }

  handleClick = instanceId => {
    localStorage.removeItem('token');
  }



  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>


              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-bell-55" />
                  {this.state.notifications.length > 0 && (
                    <Badge color="white" className="badge-circle">
                      {this.state.notifications.length}
                    </Badge>
                  )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {this.state.notifications.map(notification => {
                    let date = new Date(notification.DateCreated);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    minutes = minutes < 10 ? '0' + minutes : minutes;
                    let formattedDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + hours + ':' + minutes;

                    // Determine the href based on the user role
                    let href = (this.state.user.PersonRole === 'Admin' || this.state.user.PersonRole === 'Lawyer')
                      ? `/admin/details/${notification.InstanceId}`
                      : (this.state.user.PersonRole === 'Head User' || this.state.user.PersonRole === 'User')
                        ? `/admin/instance/${notification.InstanceId}`
                        : '#'; // default href if role does not match

                    return (
                      <DropdownItem onClick={() => window.location.href = href} key={notification.InstanceId}>
                        <div>
                          <strong>{notification.Message}</strong>
                          <p className="text-muted">{formattedDate}</p>
                        </div>
                      </DropdownItem>
                    )
                  })}

                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.user.DisplayName}  <i className="fa fa-caret-down" />
                      </span>

                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to={"/admin/user/" + this.state.user.Id + "/"} tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Προφίλ</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Ρυθμίσεις</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/support" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Υποστήριξη</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/auth/login" onClick={() => { this.handleClick() }}>
                    <i className="ni ni-button-power" />
                    <span>Αποσύνδεση</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
